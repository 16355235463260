<template>
  <b-card
    class="info-card"
  >
    <b-row>
      <b-col md="8">
        <div class="info-title">
          {{ businessName }}
        </div>
        <div
          v-if="plan"
          class="info-section"
        >
          {{ $t('subscription.package-name') }}: <span class="font-weight-bold">{{ packageName }} </span>
          <b-badge :variant="expired ? 'danger' : 'success' ">
            {{ $t(`subscription.${expired ? 'inactive' : 'active'}`) }}
          </b-badge>
        </div>
        <div
          v-if="expiresIn"
          class="info-section"
        >
          {{ $t('subscription.expiry-date') }}: <span class="font-weight-bold">{{ expiresIn }}</span>
        </div>
        <div
          v-if="price"
          class="info-section"
        >
          {{ $t('subscription.package-price') }}: <span class="font-weight-bold">{{ packagePrice }}</span>
        </div>
      </b-col>
      <b-col
        v-if="user.logo"
        md="4"
        class="d-sm-none d-md-flex align-items-center"
      >
        <img
          width="100"
          :src="user.logo"
        >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'SubscriptionCard',
  components: {
    BCard, BRow, BCol, BBadge,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      expired: 'auth/expired',
      expiresIn: 'subscription/expiresIn',
      price: 'subscription/price',
      plan: 'subscription/plan',
    }),
    businessName() {
      return this.user?.business_name ?? ''
    },
    packageName() {
      return LocalizationService.getTranslateArEn(this.plan)
    },
    packagePrice() {
      if (this.price === 0) return this.$t('subscription.free')
      return `${this.price} ل.س `
    },
  },
}
</script>

<style lang="scss">
.info-card {

  .info-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    margin-bottom: 20px;
    color:black
  }

  .info-section {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 17px;
  }
}
</style>
