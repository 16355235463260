<template>
  <div>
    <b-row>
      <b-col md="4">
        <subscription-card />
      </b-col>
    </b-row>
    <orders-boxes />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import OrdersBoxes from '@/views/dashboard/components/OrdersBoxes'
import SubscriptionCard from '@/views/dashboard/components/SubscriptionCard'

export default {
  components: {
    SubscriptionCard,
    OrdersBoxes,
    BRow,
    BCol,
  },
}
</script>
