import $http from '@/libs/axios'
import FormsService from '@/services/forms-service'
// @ts-ignore
import { saveAs } from 'file-saver'

class OrdersAPIs {
  static async delete(id: number) {
    return (await $http.delete(`orders/${id}`)).data
  }

  static async list() {
    return (await $http.get('orders')).data
  }

  static async forDash() {
    return (await $http.get('dashboard/orders')).data
  }

  static async process(data: any) {
    return (await $http.post('orders', data)).data
  }

  static async show(id: number) {
    return (await $http.get(`orders/${id}`)).data
  }

  static async export(filters: any) {
    const url = `/order/export?${FormsService.prepareQueryString(filters, true)}`
    const file = (await $http.get(url, { responseType: 'blob' })).data
    saveAs(file, 'orders.xlsx')
  }
}

export default OrdersAPIs
