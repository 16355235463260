<template>
  <div>
    <fetching-data-spinner
      :error="fetchError"
      :loading="fetching"
      :loading-title="$t('general.loading-data')"
      enable-retry
      @retry="fetchOrders"
    />
    <b-row v-if="!fetching && !fetchError">
      <b-col md="12">
        <p class="h5">
          {{ $t('dashboard.orders') }}
        </p>
      </b-col>
      <b-col
        v-for="(option,index) in options"
        :key="'option'+index"
        md="3"
      >
        <order-box
          :order="option"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import OrdersAPIs from '@/api/store/order-api'
import { OrderStatisticsStatusOptions } from '@/views/orders/config/order-options'
import OrderBox from '@/views/dashboard/components/OrderBox'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'

export default {
  name: 'OrdersBoxes',
  components: {
    FetchingDataSpinner, OrderBox, BRow, BCol,
  },
  data() {
    return {
      orders: [],
      OrderStatisticsStatusOptions,
      options: [],
      fetching: false,
      fetchError: null,
    }
  },
  mounted() {
    this.fetchOrders()
  },
  methods: {
    setOrders() {
      OrderStatisticsStatusOptions.forEach(option => {
        const count = (this.orders.filter(order => order.status === option.value) ?? []).length
        this.options.push({ ...option, count })
      })
    },
    async fetchOrders() {
      this.fetching = true
      this.fetchError = null
      await OrdersAPIs.forDash().then(res => {
        this.orders = res?.orders ?? []
        this.setOrders()
      }).catch(e => { this.fetchError = e })
        .finally(() => { this.fetching = false })
    },
  },
}
</script>

<style scoped>

</style>
