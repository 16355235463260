<template>
  <b-card
    class="position-relative mb-1"
    body-class="statistics-count-card"
  >
    <div class="d-flex">
      <div
        class="mr-1"
      >
        <b-avatar
          size="57"
          :variant="order.color"
        >
          <feather-icon
            :icon="order.icon"
            size="26"
          />
        </b-avatar>
      </div>
      <div class="w-100">
        <div class="title one-line">
          {{ $t(order.label) }}
        </div>
        <div class="title d-flex justify-content-between w-100">
          <span>{{ order.count }}</span>
          <span
            v-if="order.count >0"
            class="text-primary cursor-pointer h5"
            @click="goOrders"
          >({{ $t('dashboard.show') }})</span>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  name: 'OrderBox',
  components: {
    BCard, BAvatar,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goOrders() {
      this.$router.push({ name: 'owner:orders', query: { status: this.order.value } })
    },
  },
}
</script>

<style lang="scss">
.statistics-count-card {
  padding: 15px 22px;
  overflow: hidden;

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.39;
    color: #5e5873;
    margin-bottom: 3px;
  }
}
</style>
