import i18n from '@/libs/i18n'

export const PaymentStatusList = [
  { label: i18n.t('subscription.paid'), value: 1 },
  { label: i18n.t('subscription.unpaid'), value: 0 },
]
export const OrderTypesList = [
  { label: i18n.t('orders.types.delivery'), value: 'delivery' },
  { label: i18n.t('orders.types.pickup'), value: 'pickup' },
  { label: i18n.t('orders.types.shipment'), value: 'shipment' },
  { label: i18n.t('orders.types.internally'), value: 'internally' },
  { label: i18n.t('orders.types.service'), value: 'service' },
]
export const ORDER_STATUS = {
  PENDING: 1,
  ACCEPTED: 2,
  READY: 3,
  DELIVERED: 4,
  REJECTED: 5,
  CANCELLED: 6,
  SCHEDULED: 7,
  RETURNED: 8,
}

export const OrderStatusOptions = [
  { label: 'orders.pending', value: ORDER_STATUS.PENDING, color: 'light-secondary' },
  { label: 'orders.accepted', value: ORDER_STATUS.ACCEPTED, color: 'light-success' },
  { label: 'orders.rejected', value: ORDER_STATUS.REJECTED, color: 'danger' },
  { label: 'orders.ready', value: ORDER_STATUS.READY, color: 'success' },
  { label: 'orders.delivered', value: ORDER_STATUS.DELIVERED, color: 'gradient-success' },
  { label: 'orders.cancelled', value: ORDER_STATUS.CANCELLED, color: 'gradient-warning' },
  { label: 'orders.scheduled', value: ORDER_STATUS.SCHEDULED, color: 'gradient-secondary' },
]
export const OrderStatisticsStatusOptions = [
  {
    label: 'orders.pending', value: ORDER_STATUS.PENDING, color: 'light-warning', icon: 'WatchIcon',
  },
  {
    label: 'orders.accepted', value: ORDER_STATUS.ACCEPTED, color: 'light-success', icon: 'CheckIcon',
  },
  {
    label: 'orders.rejected', value: ORDER_STATUS.REJECTED, color: 'light-danger', icon: 'XCircleIcon',
  },
  {
    label: 'orders.ready', value: ORDER_STATUS.READY, color: 'primary', icon: 'CheckCircleIcon',
  },
  {
    label: 'orders.delivered', value: ORDER_STATUS.DELIVERED, color: 'light-primary', icon: 'TruckIcon',
  },
  {
    label: 'orders.cancelled', value: ORDER_STATUS.CANCELLED, color: 'danger', icon: 'XIcon',
  },
  {
    label: 'orders.scheduled', value: ORDER_STATUS.SCHEDULED, color: 'secondary', icon: 'ClockIcon',
  },
]
export const OrderStatusOptionsFlows = {
  [ORDER_STATUS.PENDING]: [ORDER_STATUS.ACCEPTED, ORDER_STATUS.ACCEPTED, ORDER_STATUS.REJECTED, ORDER_STATUS.CANCELLED],
  [ORDER_STATUS.ACCEPTED]: [ORDER_STATUS.CANCELLED, ORDER_STATUS.READY],
  [ORDER_STATUS.REJECTED]: [],
  [ORDER_STATUS.READY]: [ORDER_STATUS.DELIVERED],
  [ORDER_STATUS.DELIVERED]: [],
  [ORDER_STATUS.CANCELLED]: [],
  [ORDER_STATUS.SCHEDULED]: [],
}
